import React from 'react';
import {ThemeProvider as MuiThemeProvider} from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline';
import {createTheme} from "@mui/material";
import DharmaGothic from './static/DharmaGothicE_ExBold_R.otf';

const muiTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 675,
      md: 1024,
      lg: 1300,
      xl: 1536,
      xxl: 1750,
    },
  },
  typography: {
    fontFamily: '"Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif',
    htmlFontSize: 15,
    fontSize: 18,
  },
  palette: {
    primary: {
      light: "#e04876",
      main: "#b11e4b",
    },
    secondary: {
      main: "#180045",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      @font-face {
          font-family: DharmaGothic;
          font-style: normal;
          font-display: block;
          font-weight: 600;
          src: local('DharmaGothic'), url(${DharmaGothic}) format('opentype');
      }`
    }
  }
});
const headerTheme = {
  fontFamily: '"DharmaGothic"',
}

muiTheme.typography.h1 = headerTheme;
muiTheme.typography.h2 = headerTheme;
muiTheme.typography.h3 = headerTheme;
muiTheme.typography.h4 = headerTheme;
muiTheme.typography.h5 = headerTheme;
muiTheme.typography.h6 = headerTheme;

const ThemeProvider = ({children}) => (
    <MuiThemeProvider theme={muiTheme}>
      <CssBaseline/>
      {children}
    </MuiThemeProvider>
)

export default ThemeProvider;
